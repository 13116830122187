import {RUM} from '@eda-restapp/logger'

export function initRumScenarios() {
  const pathname = window.location.pathname
  if (pathname === '/active') {
    RUM.setScenariosTimeStamp('core_init_active_page')
  }
  if (pathname === '/') {
    RUM.setScenariosTimeStamp('core_init_auth_page')
  }
  if (pathname === '/home') {
    RUM.setScenariosTimeStamp('core_init_home_page')
  }
  RUM.setScenariosTimeStamp('core_init')
}
